<template>
    <div>
        <div>
            <text-field-secondary v-model="form.name" label="성함" />
            <text-field-secondary v-model="form.phone" label="연락처" class="mt-8px mt-md-16px"/>
            <text-field-secondary v-model="form.content" label="문의내용" class="mt-8px mt-md-16px"/>
        </div>
        <v-checkbox v-model="agree" dark color="white" hide-details label="개인정보수집동의" class="v-input--density-comfortable mt-8px mt-md-16px" />
        <u-txt-default class="line-height-17 grey--text text--lighten-2 mt-4px mt-md-8px">상담신청 및 서비스 이용을 위한 상담신청에 동의합니다.</u-txt-default>
        <div class="btn-wrap">
            <v-row justify="center" class="row--xxs">
                <v-col cols="auto">
                    <btn-primary class="v-size--xx-large" @click="save">법률상담 신청하기</btn-primary>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import { PANSIM_TYPES } from "@/assets/variables"
import TextFieldSecondary from "@/components/publish/parents/forms/text-field-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";

export default {
    components: {
        TextFieldSecondary,
        BtnPrimary,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
    },
    data() {
        return {
            form: {
                name: null,
                phone: null,
                content: null
            },
            agree: false
        }
    },
    methods: {
        validate() {
            try {
                if (!this.form.name) throw new Error("성함을 입력해주세요");
                if (!this.form.phone) throw new Error("연락처를 입력해주세요");
                if (!this.form.content) throw new Error("문의내용을 입력해주세요");
                if (!this.agree) throw new Error("개인정보수집에 동의해주세요")
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (this.validate()) {
                let { form } = await api.v1.forms.post({...this.form, site: PANSIM_TYPES.DIVORCE.value});
                
                alert("고객상담신청이 완료되었습니다");
                this.$emit("close");
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-input--checkbox{
        --control-label-color: #fff;

}
</style>