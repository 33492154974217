var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "grey darken-4"
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("M")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("EDIA")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("영상자료")];
      },
      proxy: true
    }, {
      key: "titRight",
      fn: function () {
        return [_c('btn-secondary2', {
          staticClass: "w-100 v-btn--width-fixed",
          attrs: {
            "to": "/insight_media/media",
            "large": ""
          }
        }, [_vm._v("More View")])];
      },
      proxy: true
    }])
  }), _c('v-row', [_c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "w-100 h-100 mx-auto",
    attrs: {
      "data-aos": "fade-up",
      "src": "/images/main/media-img.jpg",
      "max-width": "464",
      "aspect-ratio": 464 / 544
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-row', _vm._l(_vm.insights, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": index % 2 !== 0 ? 400 : 200,
        "cols": "6"
      }
    }, [_c('v-card', {
      staticClass: "media-item",
      attrs: {
        "flat": "",
        "rounded": "0",
        "color": "transparent"
      },
      on: {
        "click": function ($event) {
          return _vm.open(item.youtube.id);
        }
      }
    }, [_c('v-img', {
      staticClass: "w-100 mx-auto",
      attrs: {
        "src": item.thumb,
        "max-width": "464",
        "aspect-ratio": 464 / 260
      }
    }, [_c('div', {
      staticClass: "media-item__thumb"
    }, [_c('icon-play', {
      staticClass: "white--text"
    })], 1)])], 1)], 1);
  }), 1)], 1)], 1), _c('v-bottom-sheet', {
    attrs: {
      "eager": "",
      "fullscreen": "",
      "content-class": "mw-100 h-100",
      "max-width": "100%",
      "width": "100%"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "z-index": "10"
    },
    attrs: {
      "x-large": "",
      "absolute": "",
      "top": "",
      "right": "",
      "icon": "",
      "text": "",
      "tile": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('icon-close')], 1), _c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "aspect-ratio": 1440 / 810
    }
  }, [_c('iframe', {
    staticClass: "w-100 h-100 d-block",
    attrs: {
      "src": 'https://www.youtube.com/embed/' + _vm.selectedVideo,
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }