<template>
    <main-section v-bind="$attrs">
        <template v-if="this.$slots['mainSectionHead']" #mainSectionHead>
            <slot name="mainSectionHead" />
        </template>
        <tit-wrap-primary v-if="this.$slots['tit']" :dark="dark" :white="white" :class="titWrapClass">
            <template #titHead><slot name="titHead" /></template>
            <template #tit><slot name="tit" /></template>
            <template #titAdd><slot name="titAdd" /></template>
            <template #titRight><slot name="titRight" /></template>
        </tit-wrap-primary>
        <slot />
        <template v-if="this.$slots['mainSectionFoot']" #mainSectionFoot>
            <slot name="mainSectionFoot" />
        </template>
    </main-section>
</template>

<script>
import MainSection from "@/sets/styles/mains/main-section.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        titWrapClass: { type: String },
        dark: { type: Boolean, default: false },
        white: { type: Boolean, default: false },
    },
    components: {
        MainSection,
        TitWrapPrimary,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped></style>
