var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-icon-flat-tile', _vm._b({
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "btn-top-primary",
    attrs: {
      "fixed": "",
      "dark": "",
      "color": "grey darken-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, 'u-btn-icon-flat-tile', _vm.$attrs, false), [_c('span', [_vm._v("Top")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }