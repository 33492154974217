var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-items"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_vm._t("default"), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/mypage/myinfo"
    }
  }, [_vm.icon ? _c('u-icon', [_vm._v("person_outline")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("Mypage")]) : _vm._e()], 1)], 1) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/join"
    }
  }, [_vm.icon ? _c('u-icon', [_vm._v("person_add_alt")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("Join")]) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.navigate.apply(null, arguments);
      }
    }
  }, [_vm.accessToken ? [_vm.icon ? _c('u-icon', [_vm._v("logout")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("Logout")]) : _vm._e()] : [_vm.icon ? _c('u-icon', [_vm._v("login")]) : _vm._e(), _vm.text ? _c('span', [_vm._v("Login")]) : _vm._e()]], 2)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }