var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon-symbol-wrap"
  }, [_c('div', {
    staticClass: "icon-symbol",
    class: {
      'icon-symbol--white': _vm.white
    }
  }, [_c('span', {
    staticClass: "icon-symbol__inner"
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }