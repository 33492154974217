var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section--lawyer grey lighten-5"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "main-section--lawyer__contents",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "main-section--lawyer__left",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "5",
      "xl": "auto",
      "order": "2",
      "order-lg": "1"
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex align-end overflow-hidden",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('v-responsive', {
    staticClass: "mx-auto w-100",
    attrs: {
      "min-width": _vm.$vuetify.breakpoint.xlOnly ? 480 : '',
      "max-width": "480",
      "aspect-ratio": 1 / 1
    }
  }, [_c('swiper', {
    ref: "swiperLeft",
    staticClass: "swiper swiper--left",
    attrs: {
      "options": _vm.swiperOptionsLeft
    }
  }, [_vm._l(_vm.lawyers, function (item, key) {
    return [key !== 'lawyer01' ? _c('swiper-slide', {
      key: key
    }, [_c('div', {
      staticClass: "image-card w-100 h-100 grey lighten-5",
      style: 'background-image: url(' + item.image + ')'
    })]) : _vm._e()];
  })], 2)], 1)], 1)]), _c('v-col', {
    staticClass: "main-section--lawyer__info",
    attrs: {
      "cols": "12",
      "lg": "7",
      "xl": "",
      "order": "1",
      "order-lg": "2"
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex align-center pt-60px pt-lg-30px py-30px pr-md-40px pr-lg-60px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('swiper', {
    ref: "swiperRight",
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptionsRight
    }
  }, [_vm._l(_vm.lawyers, function (item, key) {
    return [key !== 'lawyer01' ? _c('swiper-slide', {
      key: key
    }, [_c('card-primary', {
      staticClass: "plus-btn-card v-card--none-active-bg",
      attrs: {
        "to": `./member/${item._id}`,
        "color": "transparent"
      }
    }, [_c('tit-wrap-secondary', [_c('v-row', {
      staticClass: "row--sm",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      },
      scopedSlots: _vm._u([{
        key: "TxtAdd",
        fn: function () {
          return [_c('span', [_vm._v(_vm._s(item.job))])];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-col', {
      staticClass: "d-md-none",
      attrs: {
        "cols": "auto"
      }
    }, [_c('icon-plus', {
      staticClass: "grey--text",
      attrs: {
        "size": "small"
      }
    })], 1)], 1)], 1), _c('v-row', {
      staticClass: "lawyer-info ma-n4px my-md-n2px mx-md-n20px"
    }, _vm._l(item.career, function (detail, index) {
      return _c('v-col', {
        key: index,
        staticClass: "pa-4px py-md-2px px-md-20px",
        attrs: {
          "cols": "12",
          "md": "auto"
        }
      }, [_c('txt-dot', [_c('span', {
        domProps: {
          "innerHTML": _vm._s(detail.txt)
        }
      })])], 1);
    }), 1), _c('icon-plus', {
      staticClass: "d-none d-md-block mt-48px grey--text"
    })], 1)], 1) : _vm._e()];
  })], 2)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "main-section--lawyer__buttons w-100 h-100 d-flex d-md-block"
  }, [_c('card-primary', {
    staticClass: "swiper-btn-prev w-50 h-md-50 w-md-240px font-secondary",
    attrs: {
      "data-aos": _vm.$vuetify.breakpoint.mdAndUp ? 'fade-left' : '',
      "data-aos-delay": "200",
      "color": "primary",
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex align-center pa-16px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-primary', {
    attrs: {
      "direction": "left"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-txt-medium', {
    staticClass: "txt--xl white--text"
  }, [_vm._v("Prev")])], 1)], 1)], 1)]), _c('card-primary', {
    staticClass: "swiper-btn-next w-50 h-md-50 w-md-240px font-secondary",
    attrs: {
      "data-aos": _vm.$vuetify.breakpoint.mdAndUp ? 'fade-left' : '',
      "data-aos-delay": "400",
      "color": "grey darken-4",
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex align-center pa-16px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-txt-medium', {
    staticClass: "txt--xl white--text"
  }, [_vm._v("Next")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-primary')], 1)], 1)], 1)])], 1)])], 1), !_vm.$vuetify.breakpoint.mdAndUp ? _c('v-img', {
    staticClass: "main-section--lawyer__img",
    attrs: {
      "src": "/images/main/lawyer-bg.jpg",
      "height": "60"
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }