<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-svg icon-tel" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M20.3952 15.1277C19.1707 15.1277 17.9684 14.9362 16.8291 14.5597C16.2708 14.3693 15.5845 14.544 15.2438 14.8939L12.995 16.5915C10.387 15.1994 8.78057 13.5934 7.40745 11.0051L9.0551 8.81484C9.48318 8.38734 9.63672 7.76286 9.45276 7.17693C9.07464 6.03161 8.88255 4.8299 8.88255 3.6049C8.8826 2.71995 8.16266 2 7.27776 2H3.60484C2.71995 2 2 2.71995 2 3.60484C2 13.7481 10.252 22 20.3952 22C21.2801 22 22.0001 21.2801 22.0001 20.3952V16.7325C22 15.8477 21.2801 15.1277 20.3952 15.1277Z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-default {
        width: 28px;
    }
}
</style>
