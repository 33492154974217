var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    staticClass: "v-text-field--underlined rounded-0",
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.modelValue);
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$emit('search');
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true),
    model: {
      value: _vm.modelValue,
      callback: function ($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false));

}
var staticRenderFns = []

export { render, staticRenderFns }