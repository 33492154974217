var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({
    staticClass: "tit-wrap--sm"
  }, 'u-tit-wrap-default', _vm.$attrs, false), [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "line-height-1",
    class: _vm.dark ? 'white--text' : 'grey--text text--darken-4',
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("default")], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [this.$slots['TxtAdd'] ? _c('u-txt-default', {
    staticClass: "line-height-1",
    class: _vm.dark ? 'white--text' : 'grey--text text--darken-4',
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("TxtAdd")], 2) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }