import { render, staticRenderFns } from "./u-user-items-icon.vue?vue&type=template&id=3543d4c8&scoped=true&"
import script from "./u-user-items-icon.vue?vue&type=script&lang=js&"
export * from "./u-user-items-icon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3543d4c8",
  null
  
)

export default component.exports