var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('text-field-secondary', {
    attrs: {
      "label": "성함"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('text-field-secondary', {
    staticClass: "mt-8px mt-md-16px",
    attrs: {
      "label": "연락처"
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _c('text-field-secondary', {
    staticClass: "mt-8px mt-md-16px",
    attrs: {
      "label": "문의내용"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c('v-checkbox', {
    staticClass: "v-input--density-comfortable mt-8px mt-md-16px",
    attrs: {
      "dark": "",
      "color": "white",
      "hide-details": "",
      "label": "개인정보수집동의"
    },
    model: {
      value: _vm.agree,
      callback: function ($$v) {
        _vm.agree = $$v;
      },
      expression: "agree"
    }
  }), _c('u-txt-default', {
    staticClass: "line-height-17 grey--text text--lighten-2 mt-4px mt-md-8px"
  }, [_vm._v("상담신청 및 서비스 이용을 위한 상담신청에 동의합니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    staticClass: "v-size--xx-large",
    on: {
      "click": _vm.save
    }
  }, [_vm._v("법률상담 신청하기")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }