var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({}, 'u-tit-wrap-default', _vm.$attrs, false), [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('u-tit-dot', {
    staticClass: "tit--xl font-weight-semibold line-height-1",
    class: _vm.dark ? 'white--text' : 'grey--text text--darken-4'
  }, [_c('span', {
    staticClass: "tit__head",
    class: _vm.white ? 'white--text' : 'primary--text',
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "1000"
    }
  }, [_vm._t("titHead")], 2), _c('span', {
    staticClass: "tit__body",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "800"
    }
  }, [_vm._t("tit")], 2)]), this.$slots['titAdd'] ? _c('u-tit-subtit', {
    staticClass: "tit--xs",
    attrs: {
      "dark": _vm.dark,
      "white": _vm.white
    }
  }, [_vm._t("titAdd")], 2) : _vm._e()], 1), this.$slots['titRight'] ? _c('v-col', {
    staticClass: "mt-30px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm._t("titRight")], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }