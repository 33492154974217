<template>
    <main-section-primary class="main-section--contact grey lighten-5">
        <v-row>
            <v-col cols="12" lg="6">
                <tit-wrap-primary>
                    <template #titHead>C</template>
                    <template #tit>ONTACT US</template>
                    <template #titAdd>오시는 길</template>
                </tit-wrap-primary>
            </v-col>
            <v-col cols="12" lg="6">
                <v-row class="my-0">
                    <v-col v-for="(item, index) in info" :key="index" cols="6" class="py-0">
                        <div data-aos="fade-up" :data-aos-delay="index % 2 !== 0 ? 400 : 200" :class="index % 2 !== 0 ? 'pt-20px pt-md-24px' : ''">
                            <card-primary v-if="!item.disabled" :to="item.link" class="contact-item plus-btn-card v-card--none-active-bg">
                                <v-responsive :aspect-ratio="1 / 1" class="image-card" :style="'background-image: url('+item.image+')'">
                                    <div class="contact-item__inner white--text">
                                        <u-tit-default class="tit--sm font-weight-semibold line-height-1 mb-20px mb-md-24px">0{{index+1}}</u-tit-default>
                                        <u-tit-default class="tit--sm">{{item.title}}</u-tit-default>
                                        <icon-plus class="mt-24px mt-md-48px"/>
                                    </div>
                                </v-responsive>
                            </card-primary>
                            <card-primary v-else :to="item.link" class="contact-item contact-item--disabled v-card--none-active-bg">
                                <v-responsive :aspect-ratio="1 / 1" class="image-card" :style="'background-image: url('+item.image+')'">
                                    <div class="contact-item__inner white--text" :style="'background-image: url('+item.image+')'">
                                        <u-tit-default class="tit--sm font-primary">COMING SOON</u-tit-default>
                                        <u-txt-default class="txt--lg white--text font-secondary font-weight-semibold line-height-1 mt-4px">{{item.title}}</u-txt-default>
                                    </div>
                                </v-responsive>
                            </card-primary>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    components: {
        MainSectionPrimary,
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardPrimary,
        IconPlus,
    },
    data() {
        return {
            info: [
                {
                    title: "서울주사무소",
                    image: "/images/main/contact-img.jpg",
                    link: "/contact",
                },
                {
                    title: "수원분사무소",
                    image: "/images/main/contact-img2.jpg",
                    link: "/contact",
                },
                {
                    title: "인천분사무소",
                    image: "/images/main/contact-img3.jpg",
                    link: "/contact",
                },
                {
                    title: "in Busan",
                    image: "/images/main/contact-img4.jpg",
                    disabled: true,
                },
            ],
        };
    },
}
</script>

<style lang="scss" scoped>
.main-section{
    &--contact{
        background-image: url(/images/main/contact-bg.svg);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}
.contact-item{
    &__inner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        transition: .2s ease-out;
        &:not(.contact-item--disabled .contact-item__inner){
            background-color: rgba(0, 0, 0, .5);
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .contact-item:not(.contact-item--disabled):hover{
        .contact-item__inner{
            background-color: rgba(0, 0, 0, .8);
        }
    }
}
@media (min-width:1200px){
}

</style>
