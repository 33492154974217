var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-12px mt-md-24px mt-lg-36px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-symbol', {
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "white": _vm.white
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tit', _vm._b({
    staticClass: "line-height-1",
    class: _vm.dark ? 'white--text' : 'txt--dark',
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "400"
    }
  }, 'tit', _vm.$attrs, false), [_vm._t("default")], 2)], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "overflow-hidden"
  }, [_c('v-divider', {
    staticClass: "mw-120px mw-md-180px w-md-180px",
    class: _vm.dark ? 'white' : 'grey lighten-3',
    attrs: {
      "data-aos": "fade-right"
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }