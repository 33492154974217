<template>
    <section class="main-section--lawyer grey lighten-5">
        <v-container class="container--lg">
            <v-row no-gutters class="main-section--lawyer__contents">
                <v-col cols="12" md="" class="main-section--lawyer__left">
                    <v-row class="row--xl">
                        <v-col cols="12" lg="5" xl="auto" order="2" order-lg="1">
                            <div data-aos="fade-up" class="h-100 d-flex align-end overflow-hidden">
                                <v-responsive :min-width="$vuetify.breakpoint.xlOnly ? 480 : ''" max-width="480" :aspect-ratio="1 / 1" class="mx-auto w-100">
                                    <swiper class="swiper swiper--left" ref="swiperLeft" :options="swiperOptionsLeft">
                                        <template v-for="(item, key) in lawyers">
                                            <swiper-slide :key="key" v-if="key !== 'lawyer01'">
                                                <!-- <div class="image-card w-100 h-100 grey lighten-5" :style="'background-image: url(/images/lawyer/'+item.title+'.png)'"></div>                                             -->
                                                <div class="image-card w-100 h-100 grey lighten-5" :style="'background-image: url(' + item.image + ')'"></div>
                                            </swiper-slide>
                                        </template>
                                    </swiper>
                                </v-responsive>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="7" xl="" order="1" order-lg="2" class="main-section--lawyer__info">
                            <div data-aos="fade-up" data-aos-delay="200" class="h-100 d-flex align-center pt-60px pt-lg-30px py-30px pr-md-40px pr-lg-60px">
                                <swiper class="swiper" ref="swiperRight" :options="swiperOptionsRight">
                                    <template v-for="(item, key) in lawyers">
                                        <swiper-slide :key="key" v-if="key !== 'lawyer01'">
                                            <card-primary :to="`./member/${item._id}`" color="transparent" class="plus-btn-card v-card--none-active-bg">
                                                <tit-wrap-secondary>
                                                    <v-row align="center" class="row--sm">
                                                        <v-col cols="auto">
                                                            {{ item.name }}
                                                            <template #TxtAdd>
                                                                <span>{{ item.job }}</span>
                                                            </template>
                                                        </v-col>
                                                        <!-- Mobile + icon -->
                                                        <v-col cols="auto" class="d-md-none">
                                                            <icon-plus size="small" class="grey--text" />
                                                        </v-col>
                                                        <!-- // Mobile + icon -->
                                                    </v-row>
                                                </tit-wrap-secondary>
                                                <v-row class="lawyer-info ma-n4px my-md-n2px mx-md-n20px">
                                                    <v-col v-for="(detail, index) in item.career" :key="index" cols="12" md="auto" class="pa-4px py-md-2px px-md-20px">
                                                        <txt-dot><span v-html="detail.txt"></span></txt-dot>
                                                    </v-col>
                                                </v-row>
                                                <!-- PC + icon -->
                                                <icon-plus class="d-none d-md-block mt-48px grey--text" />
                                                <!-- // PC + icon -->
                                            </card-primary>
                                        </swiper-slide>
                                    </template>
                                </swiper>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="auto">
                    <div class="main-section--lawyer__buttons w-100 h-100 d-flex d-md-block">
                        <card-primary :data-aos="$vuetify.breakpoint.mdAndUp ? 'fade-left' : ''" data-aos-delay="200" color="primary" dark class="swiper-btn-prev w-50 h-md-50 w-md-240px font-secondary">
                            <div class="w-100 h-100 d-flex align-center pa-16px">
                                <v-row justify="center" align="center" class="row--xs">
                                    <v-col cols="auto">
                                        <icon-arrow-primary direction="left" />
                                    </v-col>
                                    <v-col cols="auto">
                                        <u-txt-medium class="txt--xl white--text">Prev</u-txt-medium>
                                    </v-col>
                                </v-row>
                            </div>
                        </card-primary>
                        <card-primary :data-aos="$vuetify.breakpoint.mdAndUp ? 'fade-left' : ''" data-aos-delay="400" color="grey darken-4" dark class="swiper-btn-next w-50 h-md-50 w-md-240px font-secondary">
                            <div class="w-100 h-100 d-flex align-center pa-16px">
                                <v-row justify="center" align="center" class="row--xs">
                                    <v-col cols="auto">
                                        <u-txt-medium class="txt--xl white--text">Next</u-txt-medium>
                                    </v-col>
                                    <v-col cols="auto">
                                        <icon-arrow-primary />
                                    </v-col>
                                </v-row>
                            </div>
                        </card-primary>
                    </div>
                </v-col>
            </v-row>
            <v-img v-if="!$vuetify.breakpoint.mdAndUp" src="/images/main/lawyer-bg.jpg" height="60" class="main-section--lawyer__img" />
        </v-container>
    </section>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TxtDot from "@/components/publish/styles/typography/txt-dot.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        TitWrapSecondary,
        CardPrimary,
        TxtDot,
        IconArrowPrimary,
        IconPlus,
    },
    data() {
        return {
            lawyers: [],
            swiperOptionsLeft: {
                effect: "fade",
                loop: true,
                slidesPerView: 1,
                speed: 500,
                touchRatio: 0,
                loopedSlides: 100,
            },
            swiperOptionsRight: {
                loop: true,
                slidesPerView: 1,
                speed: 500,
                spaceBetween: 24,
                autoHeight: true,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".main-section--lawyer .swiper-btn-next",
                    prevEl: ".main-section--lawyer .swiper-btn-prev",
                },
                loopedSlides: 100,
            },
        };
    },
    mounted() {
        this.$refs.swiperLeft.$swiper.controller.control = this.$refs.swiperRight.$swiper;
        this.$refs.swiperRight.$swiper.controller.control = this.$refs.swiperLeft.$swiper;
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            try {
                let { summary, lawyers } = await api.v1.lawyers.gets({
                    params: {
                        isRepresentative: false,
                    },
                });

                lawyers = lawyers.map((lawyer) => {
                    return {
                        ...lawyer,
                        career: lawyer.career.filter((career) => career.isMain === true),
                    };
                });

                this.lawyers = lawyers;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section--lawyer {
    overflow: hidden;
    &__buttons,
    &__img {
        min-width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}
.swiper {
    &--left {
        &,
        .swiper-wrapper,
        .swiper-slide {
            width: 100%;
            height: 100%;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section--lawyer {
        &__buttons,
        &__img {
            min-width: unset;
            left: 0;
            transform: translateX(0);
        }
        &__contents {
            position: relative;
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 240px;
                height: 100%;
                top: 0;
                right: 0;
                transform: translateX(100%);
                background-image: url(/images/main/lawyer-bg.jpg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        &__left {
            max-width: calc(100% - 240px);
        }
    }
    .lawyer-info {
        overflow: hidden;
        max-height: 84px;
        flex-direction: column;
        > [class*="col"] {
            width: 50%;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-section--lawyer {
        &__info {
            max-width: calc(100% - 540px);
        }
    }
}
</style>