<template>
    <v-dialog v-model="shows" dark max-width="546" width="100%">
        <v-card class="rounded-0">
            <v-toolbar>
                <v-spacer></v-spacer>
                <v-btn icon class="v-size--xx-large rounded-0" @click="close">
                    <icon-close size="small" />
                </v-btn>
            </v-toolbar>
            <div class="px-20px pb-20px px-md-48px pb-md-48px">
                <div class="text-center mb-16px mb-md-32px">
                    <u-tit-default class="tit--sm font-size-md-28 line-height-14 white--text">고객상담신청</u-tit-default>
                    <u-txt-default class="grey--text text--lighten-3 line-height-17 mt-4px mt-md-8px">1:1 온라인 상담 서비스를 통해 변호사의 조력을 받아보세요.</u-txt-default>
                </div>
                <div class="mb-16px mb-md-32px">
                    <v-img src="/images/main/inquiry-img.svg" max-width="450" :aspect-ratio="450 / 158" contain class="w-100 mx-auto mb-12px mb-md-24px"
                    alt="STEP 01: 온라인 상담 신청, STEP 02: 전문팀의 답변진행, STEP 03: 맞춤 법률 서비스 제공" />
                    <u-txt-default class="grey--text text--lighten-3">
                        <v-row class="row--xxs">
                            <v-col v-for="(detail, index) in step" :key="index" cols="12">
                                <v-row class="row--xxs">
                                    <v-col cols="auto">
                                        <strong class="font-weight-bold white--text">0{{index+1}}</strong>
                                    </v-col>
                                    <v-col>
                                        <p>{{detail}}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </u-txt-default>
                </div>
                <inquiry-input @close="close"/>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import InquiryInput from "@/components/client/inquiry/inquiry-input.vue";
import IconClose from "@/components/publish/styles/icons/icon-close.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";

export default {
    components: {
        InquiryInput,
        IconClose,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
    },
    data() {
        return {
            shows: false,
            step: [
                "하단의 상담 신청란을 통해 상담 내용을 입력합니다.",
                "전문팀에서 의뢰인께 유선 연락을 드립니다.",
                "유선 연락을 통해 의뢰인 맞춤 법률서비스를 제공합니다.",
            ]
        }
    },
    methods:{
        close(){
            this.shows = false;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep{
    .v-dialog{
        border-top: 1px solid var(--v-primary-base);
    }
}
</style>