var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'v-dialog', _vm.$attrs, false), [_c('v-card', {
    staticClass: "rounded-0"
  }, [_c('v-toolbar', [_c('v-spacer'), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large rounded-0",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btnAttrs), false), [_c('icon-close', {
    attrs: {
      "size": "small"
    }
  })], 1)], 1), this.$slots['dialogHead'] ? _c('div', {
    staticClass: "pb-20px pb-md-60px"
  }, [_vm._t("dialogHead")], 2) : _vm._e(), _c('div', {
    staticClass: "px-20px pb-20px px-md-60px pb-md-80px"
  }, [this.$slots['tit'] ? _c('tit', {
    staticClass: "tit-wrap--xs text-center mb-20px mb-md-40px"
  }, [_vm._t("tit")], 2) : _vm._e(), _vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }