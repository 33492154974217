<template>
    <logo class="logo--dark">
        <slot />
    </logo>
</template>

<script>
import Logo from "@/components/publish/styles/headers/logo.vue";

export default {
    props: {},
    components: {
        Logo,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
