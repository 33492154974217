var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticStyle: {
      "background-image": "url(/images/main/solution-bg.jpg)"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("S")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("OLUTION")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("단계별 솔루션")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('div', {
    staticClass: "pl-lg-42px"
  }, [_c('v-row', _vm._l(_vm.info, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('dialog-primary', {
      attrs: {
        "btnAttrs": {
          color: 'grey lighten-3'
        },
        "dark": "",
        "max-width": "1440"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "solution-item image-card plus-btn-card",
            class: index % 2 !== 0 ? 'ml-20px ml-md-80px' : 'mr-20px mr-md-80px',
            style: 'background-image: url(' + item.image + ');',
            attrs: {
              "tile": "",
              "flat": ""
            }
          }, 'v-card', attrs, false), on), [_c('div', {
            staticClass: "solution-item__inner pa-20px pa-md-30px pa-lg-48px"
          }, [_c('v-row', {
            staticClass: "row--xs",
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', [_c('u-tit-default', {
            staticClass: "tit--sm",
            attrs: {
              "data-aos": "fade-up"
            }
          }, [_vm._v(_vm._s(item.title))])], 1), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('div', {
            attrs: {
              "data-aos": "fade-up",
              "data-aos-delay": "200"
            }
          }, [_c('icon-plus', {
            staticClass: "grey--text"
          })], 1)])], 1)], 1)])];
        }
      }], null, true)
    }, [_c('u-txt-default', {
      staticClass: "txt--dark"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "d-none d-lg-block",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "7"
      }
    }, [_c('div', {
      staticClass: "white pa-16px text-center"
    }, [_c('p', {
      staticClass: "font-weight-medium grey--text text-darken-4"
    }, [_vm._v("상황")])])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('div', {
      staticClass: "white pa-16px text-center"
    }, [_c('p', {
      staticClass: "font-weight-medium grey--text text-darken-4"
    }, [_vm._v("프로그램")])])])], 1)], 1), _vm._l(item.infoDetail, function (item) {
      return _c('v-col', {
        key: item.title,
        attrs: {
          "cols": "12"
        }
      }, [_c('v-row', {
        staticClass: "mx-md-n12px",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('v-col', {
        staticClass: "px-md-12px",
        attrs: {
          "cols": "12",
          "lg": "7"
        }
      }, [_c('v-row', {
        staticClass: "h-100",
        class: _vm.$vuetify.breakpoint.lgAndUp ? '' : 'border-b',
        attrs: {
          "no-gutters": ""
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "12",
          "lg": "5"
        }
      }, [_c('div', {
        staticClass: "h-100 white--text",
        class: item.color
      }, [_c('div', {
        staticClass: "h-100 pa-16px pa-md-30px pa-lg-48px"
      }, [_c('v-row', {
        staticClass: "row--xs flex-lg-column h-100"
      }, [_c('v-col', {
        attrs: {
          "cols": "12",
          "lg": ""
        }
      }, [_c('u-tit-default', {
        staticClass: "tit--sm line-height-15"
      }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1), _c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('p', {
        staticClass: "line-height-15"
      }, [_vm._v(" " + _vm._s(item.txt) + " ")])])], 1)], 1)])]), _c('v-col', {
        attrs: {
          "cols": "12",
          "lg": "7"
        }
      }, [_c('div', {
        staticClass: "h-100 white"
      }, [_c('div', {
        staticClass: "pa-16px pa-md-30px pa-lg-48px"
      }, [_c('u-tit-default', {
        staticClass: "tit--sm font-weight-medium font-primary"
      }, [_vm._v(" " + _vm._s(item.solution) + " ")]), _vm._l(item.solutionTxt, function (detail) {
        return _c('p', {
          key: detail,
          staticClass: "grey--text text--lighten-1 line-height-15 mt-12px mt-md-24px"
        }, [_vm._v(" " + _vm._s(detail) + " ")]);
      })], 2)])])], 1)], 1), _c('v-col', {
        staticClass: "px-md-12px",
        attrs: {
          "cols": "12",
          "lg": "5"
        }
      }, [_c('div', {
        staticClass: "h-100 white pa-16px pa-md-30px pa-lg-48px"
      }, [_c('v-row', _vm._l(item.program, function (item) {
        return _c('v-col', {
          key: item.title,
          attrs: {
            "cols": "12"
          }
        }, [_c('u-tit-default', {
          staticClass: "tit--sm font-weight-medium font-primary"
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-row', {
          staticClass: "row--sm pt-20px pt-md-24px"
        }, [!item.txtDetail ? _vm._l(item.txt, function (detail, index) {
          return _c('v-col', {
            key: index,
            attrs: {
              "cols": "12"
            }
          }, [_c('v-row', {
            attrs: {
              "no-gutters": ""
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('u-tit-default', {
            staticClass: "tit--sm line-height-1 font-weight-semibold primary--text w-40px"
          }, [_vm._v(" 0" + _vm._s(index + 1) + " ")])], 1), _c('v-col', [_c('p', {
            staticClass: "grey--text text--lighten-1 line-height-15"
          }, [_vm._v(" " + _vm._s(detail) + " ")])])], 1)], 1);
        }) : item.txtDetail ? _vm._l(item.txtDetail, function (item, index) {
          return _c('v-col', {
            key: index,
            attrs: {
              "cols": "12"
            }
          }, [_c('v-row', {
            attrs: {
              "no-gutters": ""
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('u-tit-default', {
            staticClass: "tit--sm line-height-1 font-weight-semibold primary--text w-40px"
          }, [_vm._v(" 0" + _vm._s(index + 1) + " ")])], 1), _c('v-col', [_c('p', {
            staticClass: "grey--text text--darken-4 line-height-15 pb-4px pb-md-8px"
          }, [_vm._v(" " + _vm._s(item.title) + " ")]), _vm._l(item.txt, function (detail, index) {
            return _c('txt-dot', {
              key: index,
              staticClass: "grey--text text--lighten-1",
              class: index !== 0 ? 'mt-2px mt-md-4px' : ''
            }, [_vm._v(" " + _vm._s(detail) + " ")]);
          })], 2)], 1)], 1);
        }) : _vm._e()], 2)], 1);
      }), 1)], 1)])], 1)], 1);
    })], 2)], 1)], 1)], 1);
  }), 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }