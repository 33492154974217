<template>
    <v-dialog v-model="shows" v-bind="$attrs">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card class="rounded-0">
            <v-toolbar>
                <v-spacer></v-spacer>
                <v-btn v-bind="{ ...btnAttrs }"  icon class="v-size--xx-large rounded-0" @click.stop="close">
                    <icon-close size="small" />
                </v-btn>
            </v-toolbar>
            <div v-if="this.$slots['dialogHead']" class="pb-20px pb-md-60px">
                <slot name="dialogHead" />
            </div>
            <div class="px-20px pb-20px px-md-60px pb-md-80px">
                <tit v-if="this.$slots['tit']" class="tit-wrap--xs text-center mb-20px mb-md-40px"><slot name="tit" /></tit>
                <slot />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import IconClose from "@/components/publish/styles/icons/icon-close.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {
        btnAttrs: { type: Object, default: () => ({}) },
    },
    components: {
        UIcon,
        IconClose,
        Tit,
    },
    data() {
        return {
            shows: false,
        };
    },
    mounted() {},
    methods: {
        close() {
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep{
    .v-dialog{
        border-top: 1px solid var(--v-primary-base);
    }
}
</style>
