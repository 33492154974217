import auction from "./auction";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import fairs from "./fairs";
import forms from "./forms";
import insights from "./insights";
import me from "./me";
import lawyers from "./lawyers";
import message from "./message";
import setting from "./setting";
import practice from "./practice";
import shop from "./shop";
import success from "./success";
import terms from "./terms";
import users from "./users";
import popups from "./popups";

export default {
    auction,
    banners,
    boards,
    center,
    fairs,
    forms,
    insights,
    me,
    lawyers,
    message,
    setting,
    success,
    practice,
    shop,
    terms,
    users,
    popups,
};
