var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tit', _vm._b({}, 'tit', _vm.$attrs, false), [_c('span', {
    staticClass: "tit__dot",
    attrs: {
      "data-aos": "zoom-in",
      "data-aos-delay": "1000"
    }
  }), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }