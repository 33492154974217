var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-select-underlined', _vm._b({
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.modelValue);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('icon-arrow-secondary', {
          staticClass: "grey--text text--darken-4",
          attrs: {
            "size": "xx-large",
            "direction": "down"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true),
    model: {
      value: _vm.modelValue,
      callback: function ($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, 'u-select-underlined', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }