var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "main-section--contact grey lighten-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('tit-wrap-primary', {
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("C")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("ONTACT US")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("오시는 길")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    staticClass: "my-0"
  }, _vm._l(_vm.info, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "py-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('div', {
      class: index % 2 !== 0 ? 'pt-20px pt-md-24px' : '',
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": index % 2 !== 0 ? 400 : 200
      }
    }, [!item.disabled ? _c('card-primary', {
      staticClass: "contact-item plus-btn-card v-card--none-active-bg",
      attrs: {
        "to": item.link
      }
    }, [_c('v-responsive', {
      staticClass: "image-card",
      style: 'background-image: url(' + item.image + ')',
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "contact-item__inner white--text"
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-semibold line-height-1 mb-20px mb-md-24px"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('u-tit-default', {
      staticClass: "tit--sm"
    }, [_vm._v(_vm._s(item.title))]), _c('icon-plus', {
      staticClass: "mt-24px mt-md-48px"
    })], 1)])], 1) : _c('card-primary', {
      staticClass: "contact-item contact-item--disabled v-card--none-active-bg",
      attrs: {
        "to": item.link
      }
    }, [_c('v-responsive', {
      staticClass: "image-card",
      style: 'background-image: url(' + item.image + ')',
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('div', {
      staticClass: "contact-item__inner white--text",
      style: 'background-image: url(' + item.image + ')'
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-primary"
    }, [_vm._v("COMING SOON")]), _c('u-txt-default', {
      staticClass: "txt--lg white--text font-secondary font-weight-semibold line-height-1 mt-4px"
    }, [_vm._v(_vm._s(item.title))])], 1)])], 1)], 1)]);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }