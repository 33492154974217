var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticStyle: {
      "background-image": "url(/images/main/introduction-bg.jpg)"
    },
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("I")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("NTRODUCTION")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("리브이혼센터 소개")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "introduction-item",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "w-100 d-flex flex-column align-center text-center px-16px py-20px py-md-0",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 200 * (index + 1)
      }
    }, [_c('v-img', {
      staticClass: "mb-24px mb-md-48px",
      attrs: {
        "src": item.icon,
        "width": _vm.$vuetify.breakpoint.mdAndUp ? 80 : 60,
        "contain": "",
        "aspect-ratio": 1 / 1
      }
    }), _c('u-tit-default', {
      staticClass: "primary--text font-primary"
    }, [_vm._v(_vm._s(item.title))]), _c('u-txt-default', {
      staticClass: "line-height-15 white--text mt-8px mt-md-16px"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.txt)
      }
    })])], 1)]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }