var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-floating"
  }, [_c('div', {
    staticClass: "mobile-floating__inner"
  }, [_c('v-row', {
    staticClass: "mobile-floating__bottom-card-group",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.quick, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "color": item.color,
        "dark": "",
        "flat": "",
        "rounded": "0",
        "height": "36",
        "to": item.to ? item.to : null,
        "href": item.href ? item.href : null,
        "target": item.href ? '_blank' : null
      },
      on: {
        "click": function ($event) {
          return _vm.handleChatClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "h-100 d-flex justify-center align-center pa-16px pa-md-24px px-lg-40px"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c(item.icon, {
      tag: "component",
      staticClass: "icon--size-small"
    })], 1), _c('v-col', {
      staticClass: "text-md-left",
      attrs: {
        "cols": "auto"
      }
    }, [_c('u-tit-default', {
      staticClass: "tit--sm white--text line-height-1"
    }, [_vm._v(_vm._s(item.title))])], 1)], 1)], 1)])], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }