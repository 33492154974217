<template>
    <div class="icon icon-close" :class="[size ? 'icon--size-' + size : '']"></div>
</template>

<script>
export default {
    props: {
        size: { type: String, default: "default" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-close {
    position: relative;
    transform: rotate(45deg);
    transition: 0.25s ease-out;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 1px;
        display: block;
        background-color: currentColor;
    }
    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &.icon--size {
        &-small {
            width: 20px;
            height: 20px;
        }
        &-default {
            width: 26px;
            height: 26px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon-close {
        &.icon--size {
            &-small {
                width: 24px;
                height: 24px;
            }
            &-default {
                width: 38px;
                height: 38px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
