var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual white--text"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.slide, function (item, index) {
    return _c('swiper-slide', {
      key: index,
      staticClass: "main-visual__slide"
    }, [_c('div', {
      staticClass: "main-visual__slide__inner"
    }, [_c('v-container', {
      staticClass: "container--lg"
    }, [_c('div', {
      staticClass: "main-visual__slide__ani"
    }, _vm._l(item.title, function (detail) {
      return _c('h2', {
        key: detail,
        staticClass: "main-visual__slide__title",
        domProps: {
          "innerHTML": _vm._s(detail)
        }
      });
    }), 0)])], 1), _c('div', {
      staticClass: "main-visual__slide__bg",
      style: 'background-image: url(' + item.image + ');'
    })]);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('swiper-pagination-primary', {
    staticClass: "swiper-pagination--light"
  })], 1)], 2), _c('div', {
    staticClass: "main-visual__bottom d-none d-md-block"
  }, [_c('div', {
    staticClass: "main-visual__bottom-inner"
  }, [_c('v-row', {
    staticClass: "main-visual__bottom-card-group",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.quick, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "4"
      }
    }, [_c('v-card', {
      staticClass: "main-visual__bottom-card",
      attrs: {
        "color": item.color,
        "dark": "",
        "flat": "",
        "rounded": "0",
        "height": "100",
        "to": item.to ? item.to : null,
        "href": item.href ? item.href : null,
        "target": item.href ? '_blank' : null
      },
      on: {
        "click": function ($event) {
          return _vm.handleChatClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "h-100 d-flex align-center pa-16px pa-md-24px px-lg-40px"
    }, [_c('v-row', {
      staticClass: "row--xs",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c(item.icon, {
      tag: "component"
    })], 1), _c('v-col', {
      staticClass: "text-center text-md-left mt-4px mt-md-0",
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('u-tit-default', {
      staticClass: "tit--sm white--text line-height-1"
    }, [_vm._v(_vm._s(item.title))])], 1), _c('v-col', {
      staticClass: "d-none d-md-block",
      attrs: {
        "cols": "auto"
      }
    }, [_c('icon-arrow-primary', {
      staticClass: "main-visual__bottom-arrow"
    })], 1)], 1)], 1)])], 1);
  }), 1)], 1)]), _c('inquiry-dialog', {
    ref: "inquiry-dialog"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }