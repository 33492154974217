var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', _vm._b({
    scopedSlots: _vm._u([this.$slots['mainSectionHead'] ? {
      key: "mainSectionHead",
      fn: function () {
        return [_vm._t("mainSectionHead")];
      },
      proxy: true
    } : null, this.$slots['mainSectionFoot'] ? {
      key: "mainSectionFoot",
      fn: function () {
        return [_vm._t("mainSectionFoot")];
      },
      proxy: true
    } : null], null, true)
  }, 'main-section', _vm.$attrs, false), [this.$slots['tit'] ? _c('tit-wrap-primary', {
    class: _vm.titWrapClass,
    attrs: {
      "dark": _vm.dark,
      "white": _vm.white
    },
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._t("titHead")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._t("tit")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._t("titAdd")];
      },
      proxy: true
    }, {
      key: "titRight",
      fn: function () {
        return [_vm._t("titRight")];
      },
      proxy: true
    }], null, true)
  }) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }