<template>
    <div class="icon icon-plus" :class="[size ? 'icon--size-' + size : '']"></div>
</template>

<script>
export default {
    props: {
        size: { type: String, default: "default" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-plus {
    position: relative;
    transform: rotate(0);
    transition: 0.25s ease-out;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 2px;
        display: block;
        background-color: currentColor;
    }
    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &.icon--size {
        &-small {
            width: 26px;
            height: 26px;
        }
        &-default {
            width: 30px;
            height: 30px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon-plus {
        &.icon--size {
            &-small {
                width: 36px;
                height: 36px;
            }
            &-default {
                width: 40px;
                height: 40px;
            }
        }
    }
}
@media (min-width:1024px){
    .plus-btn-card:hover{
        .icon-plus{
            transform: rotate(90deg);
        }
    }
}
@media (min-width:1200px){
}

</style>
