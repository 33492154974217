<template>
    <u-text-field-underlined v-model="modelValue" v-bind="$attrs" dark @input="$emit('input', modelValue)">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-text-field-underlined>
</template>

<script>
import UTextFieldUnderlined from "@/components/publish/styles/forms/u-text-field-underlined.vue";

export default {
    props: {
        value: { type: String, default: null },
    },
    components: {
        UTextFieldUnderlined,
    },
    data() {
        return {
            modelValue: this.$props.value,
        };
    },
    watch: {
        value() {
            this.modelValue = this.value;
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-text-field{
    --input-border-color: var(--v-grey-lighten1);
    --input-focused-border-color: var(--v-primary-base);
    --input-label-color: var(--v-grey-lighten1);
    --input-focused-label-color: #fff;
    --input-padding-right-comfortable: 0px;
    --input-padding-left-comfortable: 0;
}
</style>
