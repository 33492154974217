<template>
    <card-secondary v-bind="$attrs" :to="to" class="story-item v-card--none-active-bg">
        <v-responsive :aspect-ratio="384 / 524" class="story-item__inner image-card w-100" :style="'background-image: url('+thumb+');'">
            <div class="story-item__contents white--text">
                <v-responsive :aspect-ratio="384 / 442" class="story-item__stemp"/>
                <div class="d-none d-lg-block">
                    <v-responsive :aspect-ratio="1 / 1" class="ml-auto border" :class="large ? 'w-100px' : 'w-80px'"
                    content-class="d-flex align-center justify-center"
                    style="background-color: rgba(79, 89, 101, .5) !important">
                        <icon-plus :size="large ? 'default' : 'small'"/>
                    </v-responsive>
                </div>
            </div>
        </v-responsive>
        <div class="story-item__hover pa-16px pa-md-24px">
            <u-tit-default class="story-item__title tit--sm font-weight-medium text-truncate-2">
                <slot />
            </u-tit-default>
            <u-txt-default class="grey--text text--lighten-3" :class="large ? 'mt-12px mt-md-24px' : 'mt-10px mt-md-20px'">
                <slot name="date" />
            </u-txt-default>
            <icon-plus :size="large ? 'default' : 'small'" :class="large ? 'mt-30px mt-md-60px' : 'mt-24px mt-md-48px'" />
        </div>
    </card-secondary>
</template>

<script>
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    props: {
        to: { type: String, default: '' },
        large: { type: Boolean, default: false },
        thumb: { type: String, default: '' },
    },
    components: {
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardSecondary,
        IconPlus,
    },
}
</script>

<style lang="scss" scoped>
.story-item{
    width: 100%;
    box-sizing: border-box;
    &__stemp{
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url(/images/sub/story/story-stemp.svg);
    }
    &__hover{
        background-color: #fff;
        border-top: 1px solid var(--border-color);
    }
    &__title{
        min-height: 52px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .story-item{
        &__title{
            min-height: 64px;
        }
    }
}
@media (min-width:1024px){
    .story-item{
        &__contents{
            opacity: 1;
            visibility: visible;
            transition: .3s ease-out;
        }
        &__hover{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: rgba(0, 0, 0, .5); 
            border-top: 0;  
            opacity: 0;
            visibility: hidden;         
            transition: .3s ease-out;
            box-sizing: border-box;
            >*{   
                opacity: 0;
                visibility: hidden;         
                transform: scale(1.4);
                transition: .3s ease-out;
            }
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 5px solid var(--v-primary-base);
            top: 0;
            left: 0;
            transform: scaleY(0);
            opacity: 0;
            visibility: hidden;
            transition: .3s ease-out;
            box-sizing: border-box;
        }
        &:hover{
            .story-item__contents{
                opacity: 0;
                visibility: hidden;
            }
            .story-item__hover{
                opacity: 1;
                visibility: visible;
                >*{   
                    opacity: 1;
                    visibility: visible;         
                    transform: scale(1);
                    &:nth-child(1){
                        transition: .5s .1s ease-out;
                    }
                    &:nth-child(2){
                        transition: .5s .2s ease-out;
                    }
                    &:nth-child(3){
                        transition: .5s .3s ease-out;
                    }
                }
            }
            &::after{
                opacity: 1;
                visibility: visible;
                transform: scaleY(1);
                transition: .6s .3s ease-out;
            }
        }
        &__title{
            min-height: 0;
        }
    }
}
@media (min-width:1200px){
}

</style>