<template>
    <u-dialog-top-line v-model="shows" v-bind="$attrs">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-dialog-top-line>
</template>

<script>
import UDialogTopLine from "@/components/publish/styles/dialogs/u-dialog-top-line.vue";

export default {
    props: {},
    components: {
        UDialogTopLine,
    },
    data() {
        return {
            shows: false,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
