var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card-secondary', _vm._b({
    staticClass: "story-item v-card--none-active-bg",
    attrs: {
      "to": _vm.to
    }
  }, 'card-secondary', _vm.$attrs, false), [_c('v-responsive', {
    staticClass: "story-item__inner image-card w-100",
    style: 'background-image: url(' + _vm.thumb + ');',
    attrs: {
      "aspect-ratio": 384 / 524
    }
  }, [_c('div', {
    staticClass: "story-item__contents white--text"
  }, [_c('v-responsive', {
    staticClass: "story-item__stemp",
    attrs: {
      "aspect-ratio": 384 / 442
    }
  }), _c('div', {
    staticClass: "d-none d-lg-block"
  }, [_c('v-responsive', {
    staticClass: "ml-auto border",
    class: _vm.large ? 'w-100px' : 'w-80px',
    staticStyle: {
      "background-color": "rgba(79, 89, 101, .5) !important"
    },
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('icon-plus', {
    attrs: {
      "size": _vm.large ? 'default' : 'small'
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "story-item__hover pa-16px pa-md-24px"
  }, [_c('u-tit-default', {
    staticClass: "story-item__title tit--sm font-weight-medium text-truncate-2"
  }, [_vm._t("default")], 2), _c('u-txt-default', {
    staticClass: "grey--text text--lighten-3",
    class: _vm.large ? 'mt-12px mt-md-24px' : 'mt-10px mt-md-20px'
  }, [_vm._t("date")], 2), _c('icon-plus', {
    class: _vm.large ? 'mt-30px mt-md-60px' : 'mt-24px mt-md-48px',
    attrs: {
      "size": _vm.large ? 'default' : 'small'
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }