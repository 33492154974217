var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "image-card",
    staticStyle: {
      "background-image": "url(/images/main/gate-bg.jpg)"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.gate, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "4",
        "lg": "2"
      }
    }, [_c('v-card', {
      staticClass: "gate-item h-100 plus-btn-card v-card--none-active-bg",
      attrs: {
        "href": item.href,
        "target": "_blank",
        "color": "transparent",
        "flat": "",
        "rounded": "0",
        "dark": ""
      },
      on: {
        "click": function ($event) {
          return _vm.clickAlert(item);
        }
      }
    }, [_c('div', {
      staticClass: "gate-item__inner pa-20px pa-md-24px py-lg-160px"
    }, [_c('v-row', {
      staticClass: "row--xs flex-lg-column h-100",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-lg-center"
    }, [_c('u-tit-default', {
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    })]), _c('u-txt-default', {
      staticClass: "white--text line-height-15 mt-8px mt-md-16px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_vm._v(_vm._s(item.txt))])], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "400"
      }
    }, [_c('icon-plus')], 1)])], 1)], 1)])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }