<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="icon-svg icon-play" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M20 33L32 24L20 15V33ZM24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40Z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-default {
        width: 40px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon--size {
        &-default {
            width: 48px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
