<template>
    <div class="mobile-floating">
        <div class="mobile-floating__inner">
            <v-row no-gutters class="mobile-floating__bottom-card-group">
                <v-col v-for="(item, index) in quick" :key="index" cols="4">
                    <v-card :color="item.color" dark flat rounded="0" height="36" :to="item.to ? item.to : null" :href="item.href ? item.href : null" :target="item.href ? '_blank' : null" @click="handleChatClick(item)">
                        <div class="h-100 d-flex justify-center align-center pa-16px pa-md-24px px-lg-40px">
                            <v-row align="center" justify="center" class="row--xs">
                                <v-col cols="auto">
                                    <component :is="item.icon" class="icon--size-small" />
                                </v-col>
                                <v-col cols="auto" class="text-md-left">
                                    <u-tit-default class="tit--sm white--text line-height-1">{{ item.title }}</u-tit-default>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import IconChat from "@/components/publish/styles/icons/icon-chat.vue";
import IconTel from "@/components/publish/styles/icons/icon-tel.vue";
import IconKakao from "@/components/publish/styles/icons/icon-kakao.vue";

export default {
    components: {
        IconChat,
        IconTel,
        IconKakao,
    },
    data: () => ({
        quick: [
            {
                title: "상담 문의",
                icon: "icon-chat",
                color: "grey darken-2",
            },
            {
                title: "전화 상담",
                icon: "icon-tel",
                color: "grey darken-3",
                href: "tel:16600722",
            },
            {
                title: "카톡 상담",
                icon: "icon-kakao",
                color: "grey darken-4",
                href: "http://pf.kakao.com/_xicPuG/chat",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.mobile-floating {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 10;
    &__inner {
        max-width: 1920px;
        margin: 0 auto;
    }
}
@media (min-width: 768px) {
    .mobile-floating {
        display: none;
    }
}
:deep() {
    .icon--size {
        &-small {
            width: 18px;
        }
    }
}
</style>