<template>
    <u-txt-default class="line-height-15">
        <v-row no-gutters >
            <v-col v-if="!dotNone && $slots['default']" cols="auto">
                <p class="pr-4px pr-md-8px">·</p>
            </v-col>
            <v-col>
                <p><slot /></p>
            </v-col>
        </v-row>
    </u-txt-default>
</template>

<script>
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
export default {
    props:{
        dotNone: { type: Boolean, default: false },
    },
    components: {
        UTxtDefault
    },
}
</script>

<style>

</style>