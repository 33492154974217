<template>
    <v-tabs v-model="tabs" v-bind="{ ...attrs_tabs_common, ...$attrs }" class="v-tabs--dot">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tabs>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

export default {
    props: {
        value: { type: [String, Number] },
    },
    components: {},
    data() {
        return {
            attrs_tabs_common,
        };
    },
    computed: {
        tabs: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tabs--dot {
    &.v-tabs {
        &,
        &--density-comfortable {
            height: calc(var(--tab-height-comfortable) + 4px);
            margin-bottom: -4px;
        }
        &--density-compact {
            height: calc(var(--tab-height-compact) + 4px);
            margin-bottom: -4px;
        }
        &--density-default {
            height: calc(var(--tab-height) + 4px);
            margin-bottom: -4px;
        }
        &--border,
        &--wide-border {
            &::before {
                bottom: 4px;
            }
        }
    }
}
</style>
