<template>
    <app-primary class="app--main">

        <main-visual />
        <main-professional />
        <main-lawyer />
        <main-story />
        <!-- <main-judgment /> -->
        <main-introduction />
        <main-business />
        <main-solution />
        <main-media />
        <main-insights />
        <main-contact />
        <main-gate />

        <popup-layer />

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";

import MainVisual from "@/sets/styles/mains/main-visual.vue";
import MainIntroduction from "@/sets/styles/mains/main-introduction.vue";
import MainProfessional from "@/sets/styles/mains/main-professional.vue";
import MainLawyer from "@/sets/styles/mains/main-lawyer.vue";
import MainBusiness from "@/sets/styles/mains/main-business.vue";
import MainSolution from "@/sets/styles/mains/main-solution.vue";
import MainStory from "@/sets/styles/mains/main-story.vue";
import MainJudgment from "@/sets/styles/mains/main-judgment.vue";
import MainMedia from "@/sets/styles/mains/main-media.vue";
import MainInsights from "@/sets/styles/mains/main-insights.vue";
import MainContact from "@/sets/styles/mains/main-contact.vue";
import MainGate from "@/sets/styles/mains/main-gate.vue";

import PopupLayer from "@/components/client/popup/popup-layer.vue";

export default {
    components: {
        AppPrimary,
        
        MainVisual,
        MainIntroduction,
        MainProfessional,
        MainLawyer,
        MainBusiness,
        MainSolution,
        MainStory,
        MainJudgment,
        MainMedia,
        MainInsights,
        MainContact,
        MainGate,

        PopupLayer,
    },
    props: {},
    data() {
        return {};
    },
};
</script>
