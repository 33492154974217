<template>
    <u-swiper-pagination-around-outlined v-bind="$attrs" class="swiper-pagination--angular">
        <slot />
    </u-swiper-pagination-around-outlined>
</template>

<script>
import USwiperPaginationAroundOutlined from "@/components/publish/styles/swipers/u-swiper-pagination-around-outlined.vue";

export default {
    props: {},
    components: {
        USwiperPaginationAroundOutlined,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
