var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "pb-0 grey lighten-5",
    scopedSlots: _vm._u([{
      key: "mainSectionFoot",
      fn: function () {
        return [_c('v-img', {
          staticClass: "w-100 mt-50px mt-md-80px mt-md-100px",
          attrs: {
            "src": "/images/main/judgment-img.jpg",
            "aspect-ratio": 1920 / 300,
            "eager": ""
          }
        })];
      },
      proxy: true
    }])
  }, [_c('tit-wrap-primary', {
    scopedSlots: _vm._u([{
      key: "titHead",
      fn: function () {
        return [_vm._v("J")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("UDGMENT")];
      },
      proxy: true
    }, {
      key: "titAdd",
      fn: function () {
        return [_vm._v("전체 성공사례")];
      },
      proxy: true
    }, {
      key: "titRight",
      fn: function () {
        return [_c('btn-secondary', {
          staticClass: "w-100 v-btn--width-fixed",
          attrs: {
            "to": "/story/representative",
            "large": ""
          }
        }, [_vm._v("More View")])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "mx-auto w-100",
    staticStyle: {
      "max-width": "464px"
    },
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('div', {
    staticClass: "judgment-slide-wrap mx-auto"
  }, [_c('swiper', {
    ref: "swiperJudgment",
    staticClass: "judgment-slide",
    attrs: {
      "options": _vm.swiperOptionJudgment
    }
  }, _vm._l(_vm.successes, function (success, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('story-item', {
      attrs: {
        "to": `/story/${success._id}`,
        "thumb": success.thumb,
        "large": ""
      },
      scopedSlots: _vm._u([{
        key: "date",
        fn: function () {
          return [_vm._v(_vm._s(_vm.$dayjs(success.createdAt).format("YYYY-MM-DD")))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(success.subject) + " ")])], 1);
  }), 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }