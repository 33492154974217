<template>
    <u-inline-group>
        <li v-for="info in infos" :key="info.title" :class="info.clear == true ? 'clear' : ''">
            {{ info.title }} : {{ info.text }}
        </li>
    </u-inline-group>
</template>

<script>
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UInlineGroup,
        UTxtDefault,
    },
    data() {
        return {
            infos: [
                { title: "대표", text: "문유진" },
                { title: "서울주사무소", text: "서울 서초구 서초대로 272 9층(한국아이비에스빌딩)" },
                { title: "수원분사무소", text: "수원시 영통구 광교중앙로 248번길 95-1 광교법조타운 7층", clear: true },
                { title: "인천분사무소", text: "인천광역시 미추홀구 소성로 171 로시스빌딩 3층" },
                { title: "대표번호", text: "1660-0722", clear: true },
                { title: "Fax", text: "02-523-0533" },
                { title: "이메일", text: "judgemind@naver.com" },
                { title: "광고책임", text: "문유진 변호사" },
                { title: "긴급통화", text: "010-8702-0200", clear: true },
                { title: "상호(법인)명", text: "법무법인 형사변호사 판심" },
                { title: "사업자 등록번호", text: "113-88-03067" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
