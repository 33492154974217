<template>
    <u-select-underlined v-model="modelValue" v-bind="$attrs" @input="$emit('input', modelValue)">
        <template #append> 
            <icon-arrow-secondary size="xx-large" direction="down" class="grey--text text--darken-4" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-select-underlined>
</template>

<script>
import IconArrowSecondary from "@/components/publish/parents/icons/icon-arrow-secondary.vue";
import USelectUnderlined from "@/components/publish/styles/forms/u-select-underlined.vue";

export default {
    props: {
        value: { type: String, default: null },
    },
    components: {
        IconArrowSecondary,
        USelectUnderlined,
    },
    data() {
        return {
            modelValue: this.$props.value,
        };
    },
    watch: {
        value() {
            this.modelValue = this.value;
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-text-field{
    --input-border-color: var(--v-primary-base);
    --input-focused-border-color: var(--v-grey-darken4);
}
</style>
