<template>
    <main-section-primary class="grey darken-4">
        <tit-wrap-primary dark>
            <template #titHead>M</template>
            <template #tit>EDIA</template>
            <template #titAdd>영상자료</template>
            <template #titRight>
                <btn-secondary2 to="/insight_media/media" large class="w-100 v-btn--width-fixed">More View</btn-secondary2>
            </template>
        </tit-wrap-primary>
        <v-row>
            <v-col cols="12" md="4" class="d-none d-md-block">
                <v-img data-aos="fade-up" src="/images/main/media-img.jpg" max-width="464" :aspect-ratio="464 / 544" class="w-100 h-100 mx-auto" />
            </v-col>
            <v-col cols="12" md="8">
                <v-row>
                    <v-col v-for="(item, index) in insights" :key="index" data-aos="fade-up" :data-aos-delay="index % 2 !== 0 ? 400 : 200" cols="6">
                        <v-card flat rounded="0" color="transparent" class="media-item" @click="open(item.youtube.id)">
                            <v-img :src="item.thumb" max-width="464" :aspect-ratio="464 / 260" class="w-100 mx-auto">
                                <div class="media-item__thumb"><icon-play class="white--text" /></div>
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-bottom-sheet v-model="show" eager fullscreen content-class="mw-100 h-100" max-width="100%" width="100%">
            <v-btn x-large absolute top right icon text tile color="white" style="z-index: 10;" @click="close">
                <icon-close />
            </v-btn>
            <v-responsive max-width="1440" :aspect-ratio="1440 / 810">
                <iframe :src="'https://www.youtube.com/embed/'+selectedVideo" class="w-100 h-100 d-block" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
            </v-responsive>
        </v-bottom-sheet>
    </main-section-primary>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from '@/assets/variables';
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";
import IconPlay from "@/components/publish/styles/icons/icon-play.vue";
import IconClose from "@/components/publish/styles/icons/icon-close.vue";

export default {
    components: {
        MainSectionPrimary,
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        BtnSecondary2,
        IconPlay,
        IconClose,
    },
    data() {
        return {
            show: false,
            selectedVideo: "",
            insights: [],
            filter: { 
                code: "youtube",                        // 영상자료
                site: PANSIM_TYPES.DIVORCE.value,       // 이혼 사이트
                // isSort: true,                        // 우선 순위 적용
                isDivorceMain: true
            },
        };
    },
    mounted (){
        this.init();
    },
    methods:{
        async init(){
            this.search();
        },
        async search(){
            try{
                let { summary, insights } = await api.v1.insights.gets({
                    headers: { limit: 4 },
                    params: this.filter
                })

                // insights = insights.filter((insight)=> insight.isDivorceMain === true)

                this.insights = insights;

            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        open(video){
            this.show = true;            
            this.selectedVideo = video;
        },
        close(){
            this.show = false;
            this.selectedVideo = "";
        }
    },
}
</script>

<style lang="scss" scoped>
.media-item{
    &__thumb{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s ease-out;
    }
}
::v-deep{
    .v-bottom-sheet{
        background-color: rgba(0, 0, 0, .8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        .v-btn--absolute.v-btn--top.v-btn--right{
            top: 6px;
            right: 6px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    ::v-deep{
        .v-bottom-sheet{
            .v-btn--absolute.v-btn--top.v-btn--right{
                top: 50px;
                right: 50px;
            }
        }
    }
}
@media (min-width:1024px){
    .media-item:hover{
        .media-item__thumb{
            background-color: rgba(0, 0, 0, .8);
        }
    }
}
@media (min-width:1200px){ 
}
</style>