<template>
    <main-section-primary class="pb-0 grey lighten-5">
        <tit-wrap-primary>
            <template #titHead>J</template>
            <template #tit>UDGMENT</template>
            <template #titAdd>전체 성공사례</template>
            <template #titRight>
                <btn-secondary to="/story/representative" large class="w-100 v-btn--width-fixed">More View</btn-secondary>
            </template>
        </tit-wrap-primary>
        <div data-aos="fade-up" data-aos-delay="200" class="mx-auto w-100" style="max-width: 464px;">
            <div class="judgment-slide-wrap mx-auto">
                <swiper ref="swiperJudgment" :options="swiperOptionJudgment" class="judgment-slide">

                    <!-- S: Story -->
                    <swiper-slide v-for="(success, index) in successes" :key="index">
                        <story-item :to="`/story/${success._id}`" :thumb="success.thumb" large>
                            {{success.subject}}
                           <template #date>{{ $dayjs(success.createdAt).format("YYYY-MM-DD") }}</template>
                        </story-item>                
                    </swiper-slide>
                    <!-- E: Story -->

                </swiper>
            </div>
        </div>
        <template #mainSectionFoot>
            <v-img src="/images/main/judgment-img.jpg" :aspect-ratio="1920 / 300" eager class="w-100 mt-50px mt-md-80px mt-md-100px" />
        </template>
    </main-section-primary>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from '@/assets/variables';
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";

import StoryItem from "@/components/client/board/skin/story/item.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        MainSectionPrimary,
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        BtnSecondary,

        StoryItem,
    },
    data() {
        return {
            successes: [],
            filter: { 
                code: "representative",                         // 문유진 변호사 성공 사례
                site: PANSIM_TYPES.DIVORCE.value,               // 이혼 사이트
                // isSort: true,                                // 우선 순위 적용 
                isDivorceMain: true
            },
            swiperOptionJudgment: {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 6,
                centeredSlides: true,
                loopedSlides: 2,
                speed: 500,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init  (){
            await this.search();
        },
        async search(routable = false){
            if (routable) {
                this.$router.push({
                    query: Object.assign({}, {...this.filter} ),
                });
            }

            try{
                let { summary, successes } = await api.v1.success.gets({
                    headers: { limit: 9 },
                    params: this.filter
                })

                // successes = successes.filter((success)=> success.isDivorceMain === true);
                
                this.successes = successes;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.judgment-slide{
    --slide-margin: 14px;
    &-wrap{
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 185.393%;
    }
    &.swiper-container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translateX(calc(var(--slide-margin) * -1));
        overflow: unset;
        ::v-deep{
            .swiper-wrapper{
                height: 100%;
            }
        }
    }
    .swiper-slide{
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        transition: .3s ease-out;
        &:not(.swiper-slide-active){
            padding-left: var(--slide-margin);
        }
        &.swiper-slide-prev{
            padding-left: 0;
            padding-right: var(--slide-margin);
            margin-left: var(--slide-margin);
        }
        &.swiper-slide-active{
            .story-item{
                box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .judgment-slide{
        --slide-margin: 34px;        
        &-wrap{
            padding-top: 191.379%;
        }
        .swiper-slide{
            &.swiper-slide-active{
                .story-item{
                    box-shadow: var(--shadow-elevation-16) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
                }
            }
        }
    }
}
@media (min-width:1024px){
    .judgment-slide{
        &-wrap{
            padding-top: 136.458%;
        }
    }
}
@media (min-width:1200px){
}

</style>