<template>
    <u-tit-wrap-default v-bind="$attrs" class="tit-wrap--sm">
        <v-row align="center" class="row--xs">
            <v-col cols="auto">
                <u-tit-default data-aos="fade-up" class="line-height-1" :class="dark ? 'white--text' : 'grey--text text--darken-4'"><slot /></u-tit-default>
            </v-col>
            <v-col cols="auto">
                <u-txt-default v-if="this.$slots['TxtAdd']" data-aos="fade-up" data-aos-delay="200" class="line-height-1" :class="dark ? 'white--text' : 'grey--text text--darken-4'"><slot name="TxtAdd" /></u-txt-default>
            </v-col>
        </v-row>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        dark: { type: Boolean, default: false },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
