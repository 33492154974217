var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer-body', {
    staticClass: "footer-body--basic",
    attrs: {
      "bodyRowAttrs": {
        class: 'row--xxl'
      },
      "bodyLeftAttrs": {
        cols: '12',
        lg: 'auto'
      },
      "bodyRightAttrs": {
        cols: '12',
        lg: ''
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }