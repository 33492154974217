<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-svg icon-chat" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M20.75 2H8.25001C7.55962 2 6.99999 2.55963 6.99999 3.25001V5.75H15.75C17.1288 5.75 18.25 6.87123 18.25 8.24999V15.5416L20.75 17L19.9166 13.25H20.75C21.4404 13.25 22 12.6902 22 11.9999V3.25001C22 2.55963 21.4404 2 20.75 2Z"/>
        <path d="M15.75 6.99999H3.25001C2.55963 6.99999 2 7.55958 2 8.24997V17C2 17.6903 2.55963 18.25 3.25001 18.25H6.58339L5.75004 22L12.1787 18.25H15.7501C16.4405 18.25 17.0001 17.6903 17.0001 17V8.24997C17 7.55958 16.4404 6.99999 15.75 6.99999ZM6.37501 14.5C6.02986 14.5 5.75 14.2201 5.75 13.8749C5.75 13.5297 6.02986 13.2499 6.37501 13.2499C6.72025 13.2499 7.00002 13.5297 7.00002 13.8749C7.00002 14.2201 6.72025 14.5 6.37501 14.5ZM9.5 14.5C9.15485 14.5 8.875 14.2201 8.875 13.8749C8.875 13.5297 9.15485 13.2499 9.5 13.2499C9.84524 13.2499 10.125 13.5297 10.125 13.8749C10.125 14.2201 9.84524 14.5 9.5 14.5ZM12.625 14.5C12.2798 14.5 12 14.2201 12 13.8749C12 13.5297 12.2798 13.2499 12.625 13.2499C12.9702 13.2499 13.25 13.5297 13.25 13.8749C13.25 14.2201 12.9702 14.5 12.625 14.5Z"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-default {
        width: 28px;
    }
}
</style>
