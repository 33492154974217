var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    staticClass: "app--main"
  }, [_c('main-visual'), _c('main-professional'), _c('main-lawyer'), _c('main-story'), _c('main-introduction'), _c('main-business'), _c('main-solution'), _c('main-media'), _c('main-insights'), _c('main-contact'), _c('main-gate'), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }