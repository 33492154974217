<template>
    <u-tit-wrap-default v-bind="$attrs">
        <v-row no-gutters align="end">
            <v-col cols="12" md="">
                <u-tit-dot :class="dark ? 'white--text' : 'grey--text text--darken-4'" class="tit--xl font-weight-semibold line-height-1">
                    <span data-aos="zoom-out" data-aos-delay="1000" class="tit__head" :class="white ? 'white--text' : 'primary--text'"><slot name="titHead"/></span>
                    <span data-aos="zoom-out" data-aos-delay="800" class="tit__body"><slot name="tit" /></span>
                </u-tit-dot>
                <u-tit-subtit v-if="this.$slots['titAdd']" :dark="dark" :white="white" class="tit--xs"><slot name="titAdd" /></u-tit-subtit>
            </v-col>
            <v-col v-if="this.$slots['titRight']" cols="12" md="auto" class="mt-30px mt-md-0">
                <slot name="titRight" />
            </v-col>
        </v-row>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDot from "@/components/publish/styles/typography/u-tit-dot.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        dark: { type: Boolean, default: false },
        white: { type: Boolean, default: false },
    },
    components: {
        UTitWrapDefault,
        UTitDot,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit__head{
    font-size: 6.4rem;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit__head{
        font-size: 10rem;
    }
}
@media (min-width:1024px){
    .tit{
        height: 88px;
        white-space: nowrap;
    }
    .tit__body{
        display: inline-flex;
        align-items: center;
        height: 52px;
    }
    .tit__head{
        font-size: 14rem;
        display: inline-flex;
        align-items: center;
        height: 88px;
    }
}
@media (min-width:1200px){
}
</style>
