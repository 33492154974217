import axios from "@/plugins/axios";

let url = "/api/console/insights";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
    postThumb(_insight, thumb) {
        var formData = new FormData();
        formData.append("thumb", thumb);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${_insight}/thumb`, formData, { headers });
    },
    deleteThumb(data) {
        return axios.delete(`${url}/${data._id}/thumb`);
    },
};
